// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"


const mainHeader = document.getElementById('main-nav');

function onScroll() {
  if (window.scrollY > 50) {
    mainHeader.classList.add('bg-white');
  } else {
    mainHeader.classList.remove('bg-white');
  }
}

window.addEventListener('scroll', onScroll);
